import { __HouseSearchParamsType } from '@/tehranto/housesList/housesList.type';
import { __TenantSearchParamsType } from '@/tehranto/roommatesList/roommatesList.type';
import { LanguageEnum, ServiceTypeEnum } from 'types';

type routeParam = string | number;
type searchServices = {
    page?: string;
    search?: string;
    order?: string;
    category?: string;
    starGTE?: number;
    starLTR?: number;
};

type searchEvents = {
    search?: string;
    page?: string;
    category?: string;
    cost?: string;
    capacity?: string;
};

// absolute paths of project pages
export const __PATH = (lang: LanguageEnum) => ({
    home: (service?: ServiceTypeEnum) => `/${lang}${service ? '?service=' + service : ''}`,
    contact: `/${lang}/contact`,
    aboutUs: `/${lang}/about-us`,
    faq: `/${lang}/faq`,
    terms: `/${lang}/terms`,
    login: (redirect?: string) => `/${lang}/login${redirect ? '?redirect=' + redirect : ''}`,
    signup: `/${lang}/signup`,
    search: `/${lang}/search`,
    categories: `/${lang}/categories`,
    blog: {
        index: (searchParams?: { page?: string; search?: string; order?: string }) =>
            `/${lang}/blog/${appendParams(searchParams)}`,
        detail: (slug: string) => `${__PATH(lang).blog.index()}${slug}`,
    },
    brand: (slug: string, searchParams?: searchServices) => {
        const queries = searchParams ? { ...searchParams } : {};
        if (!queries.page?.length || queries.page === '1') delete queries.page;
        if (!queries.order?.length || queries.order === 'most_priority') delete queries.order;
        return `/${lang}/offer/${slug}/${appendParams(queries)}`;
    },
    services: {
        index: (searchParams?: searchServices) => {
            const queries = searchParams ? { ...searchParams } : {};
            if (!queries.page?.length || queries.page === '1') delete queries.page;
            if (!queries.order?.length || queries.order === 'most_priority') delete queries.order;
            return `/${lang}/offer/${appendParams(queries)}`;
        },
        detail: (brand: string, service: string, searchParams?: searchServices) => {
            const queries = searchParams ? { ...searchParams } : {};
            if (!queries.page?.length || queries.page === '1') delete queries.page;
            if (!queries.order?.length || queries.order === 'most_priority') delete queries.order;
            return `${__PATH(lang).services.index()}${brand}/${service}/${appendParams(queries)}`;
        },
    },
    events: {
        index: (searchParams?: searchEvents) => `/${lang}/events/${appendParams(searchParams)}`,
        detail: (slug: string) => `${__PATH(lang).events.index()}${slug}`,
    },
    panel: {
        index: `/${lang}/panel`,
        profile: () => `${__PATH(lang).panel.index}/profile`,
        orders: () => `${__PATH(lang).panel.index}/orders`,
        ordersDetail: (id: routeParam) => `${__PATH(lang).panel.orders()}/${id}`,
        notification: () => `${__PATH(lang).panel.index}/notification`,
        comments: () => `${__PATH(lang).panel.index}/comments`,
        ticketing: () => `${__PATH(lang).panel.index}/ticketing`,
        mobileMenu: () => `${__PATH(lang).panel.index}/menu-mobile`,
        events: () => `${__PATH(lang).panel.index}/events`,
        myHosts: () => `${__PATH(lang).panel.events()}/mine`,
        myParticipates: () => `${__PATH(lang).panel.events()}/participate`,
        participatesDetail: (id: routeParam) => `${__PATH(lang).panel.myParticipates()}/${id}`,
        createEvent: () => `${__PATH(lang).panel.events()}/new`,
        eventDetail: (id: routeParam) => `${__PATH(lang).panel.myHosts()}/${id}`,
        editEvent: (id: routeParam) => `${__PATH(lang).panel.events()}/${id}/edit`,
        dating: (notification?: '#activate-profile' | '#no-dating-profile' | '#success-payment' | '#fail-payment') =>
            `${__PATH(lang).panel.index}/dating${notification ?? ''}`,
        housing: (params?: '#tenant-focus') => `${__PATH(lang).panel.index}/housing${params ?? ''}`,
        house: () => `${__PATH(lang).panel.housing()}/house`,
        houseCommunalForm: () => `${__PATH(lang).panel.house()}/communal-form`,
        houseIndividualForm: () => `${__PATH(lang).panel.house()}/individual-form`,
        houseDetail: (id: routeParam) => `${__PATH(lang).panel.house()}/${id}`,
        houseCommunalEdit: (id: routeParam) => `${__PATH(lang).panel.houseDetail(id)}/communal-form`,
        houseIndividualEdit: (id: routeParam) => `${__PATH(lang).panel.houseDetail(id)}/individual-form`,
        tenant: () => `${__PATH(lang).panel.housing()}/tenant`,
        tenantCommunalForm: () => `${__PATH(lang).panel.tenant()}/communal-form`,
        tenantIndividualForm: () => `${__PATH(lang).panel.tenant()}/individual-form`,
    },
    verify: {
        success: (session: string) => `/verify/success/?session_id=${session}`,
        fail: `/verify/fail`,
    },
    dating: `/${lang}/dating`,
    orderPrint: `/${lang}/orderPrint`,
    housing: {
        houses: (searchParams?: __HouseSearchParamsType) => `/${lang}/housing/houses/${appendParams(searchParams)}`,
        housesDetail: (id: routeParam) => `${__PATH(lang).housing.houses()}${id}`,
        roommates: (searchParams?: __TenantSearchParamsType) =>
            `/${lang}/housing/roommates/${appendParams(searchParams)}`,
        roommatesDetail: (id: routeParam) => `${__PATH(lang).housing.roommates()}${id}`,
    },
});

const appendParams = (searchParams?: object) =>
    searchParams && Object.keys(searchParams).length
        ? '?' + new URLSearchParams(filterObj(searchParams)).toString()
        : '';

const filterObj = (obj: any) => {
    Object.keys(obj).map((propName) => {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    });
    return obj;
};
