const api = process.env.NEXT_PUBLIC_API_URL;
const next = process.env.NEXT_PUBLIC_HOST + '/api';

// paths of api call's urls
export const __API = {
    middlewares: {
        lang: `${next}/lang`,
        login: `${next}/login`,
        logout: `${next}/logout`,
    },
    register: {
        login: `${api}/account/auth/admin_login/`,
        me: `${api}/account/me/`,
        register: `${api}/account/auth/register/`,
        verify: `${api}/account/auth/verify_code/`,
        updateMe: `${api}/account/update_me/`,
        logout: `${api}/account/auth/logout/`,
    },
    avatar: { index: `${api}/core/avatar/`, detail: (id: number) => `${api}/core/avatar/${id}/` },
    purchase_document: {
        index: `${api}/core/purchase_document/`,
        detail: (id: number) => `${api}/core/purchase_document/${id}/`,
    },
    order: {
        mine: `${api}/order/my_orders/`,
        detail: (id: number) => `${api}/order/${id}/`,
        init: `${api}/order/initialize/`,
        public: `${api}/order/public/`,
        review: (id: number) => `${api}/order/${id}/review/`,
    },
    categories: {
        list: `${api}/service/category/`,
        detail: (slug: string) => `${api}/service/category/${slug}/`,
    },
    brands: {
        index: `${api}/service/brand/`,
        detail: (slug: string) => `${api}/service/brand/${slug}/`,
    },
    services: {
        index: `${api}/service/service/`,
        detail: (slug: string) => `${api}/service/service/${slug}/`,
        siblings: (slug: string) => `${api}/service/service/${slug}/siblings/`,
    },
    ticket: {
        faq: `${api}/customer-service/faq/`,
    },
    attachment: {
        index: api + '/core/attachment/',
        detail: (id: number | string) => api + '/core/attachment/' + id + '/',
    },
    setting: {
        detail: (key: string, lang: string) => `${api}/account/setting/detail/${key}/${lang}/`,
    },
    dating: {
        list: `${api}/dating/profile/active-list/`,
        myProfile: `${api}/dating/profile/my-profile/`,
        telegram: (id: number) => `${api}/dating/profile/${id}/telegram-id/`,
        buyChance: `${api}/dating/connection/buy-chance/`,
        verify: `${api}/dating/connection/verify-payment/`,
    },
    blog: {
        index: `${api}/blog/post/`,
        detail: (slug: string) => `${api}/blog/post/${slug}/`,
    },
    location: {
        index: `${api}/core/location/`,
    },
    extraPge: (slug: string) => `${api}/blog/extra-page/${slug}/`,
    housing: {
        houseList: `${api}/housing/public/`,
        houseDetail: (id: number) => `${api}/housing/public/${id}/`,
        tenantList: `${api}/housing/tenant/public/`,
        tenantDetail: (id: number) => `${api}/housing/tenant/public/${id}/`,
        userHouseList: `${api}/housing/user/`,
        userHouseDetail: (id: number) => `${api}/housing/user/${id}/`,
        tenantUser: `${api}/housing/tenant/user/`,
        tenantUserDetail: (id: number) => `${api}/housing/tenant/user/${id}/`,
        ownerTelegram: (id: number) => `${api}/housing/public/${id}/get-telegram-id/`,
        tenantTelegram: (id: number) => `${api}/housing/tenant/user/${id}/get-telegram-id/`,
    },
};
