import { LanguageEnum } from '@/types';

export function __ToFarsi(number: string | number): string {
    const persianNumbers: { [key: string]: string } = {
        '0': '۰',
        '1': '۱',
        '2': '۲',
        '3': '۳',
        '4': '۴',
        '5': '۵',
        '6': '۶',
        '7': '۷',
        '8': '۸',
        '9': '۹',
    };

    return number.toString().replace(/\d/g, (digit) => persianNumbers[digit]);
}

export const __ToEnglish = (phoneNumber: string): string => {
    const englishDigitsRegex = /[0-9]/g;
    if (englishDigitsRegex.test(phoneNumber)) return phoneNumber;
    const persianDigitsRegex = /[\u06F0-\u06F9]/g;
    return phoneNumber.replace(persianDigitsRegex, (match) => {
        const persianToEnglishMap: { [key: string]: string } = {
            '\u06F0': '0',
            '\u06F1': '1',
            '\u06F2': '2',
            '\u06F3': '3',
            '\u06F4': '4',
            '\u06F5': '5',
            '\u06F6': '6',
            '\u06F7': '7',
            '\u06F8': '8',
            '\u06F9': '9',
        };
        return persianToEnglishMap[match];
    });
};

export const __CorrectNum = (num: string | number, lang: LanguageEnum) => {
    if (lang === LanguageEnum.fa) return __ToFarsi(num);
    return num;
};
export function __CopyToClipboard(text: string) {
    const input = document.createElement('input');
    input.value = text;
    input.style.cssText = 'opacity: 0; z-index:-10000;';
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    input.remove();
}

export const __ExtractTitle = <T>(list: { label: string; value: T }[], selected?: T) => {
    return list.find((i) => i.value === selected)?.label;
};
