import { IconType } from 'react-icons';

export type __FAQType = {
    question: string;
    answer: string;
    id: number;
    order?: number;
};

export type __AttachmentType = {
    id: number;
    file: string;
    alt?: string;
    type: __AttachmentTypeEnum;
    usage: __AttachmentUsageEnum;
};

export enum __AttachmentTypeEnum {
    image,
    video,
    document,
    audio,
    archive,
}

export enum __AttachmentUsageEnum {
    content = 1,
    ticket,
    profile,
    event,
}

export type __BestsBrandsType = {
    id: number;
    title: string;
    slug: string;
    image: __AttachmentType;
    down_vote: number;
    up_vote: number;
    ads?: boolean;
};

export type __CategoryType = {
    id: number;
    title: string;
    slug: string;
    description?: string; // html
    image?: __AttachmentType;
    parent?: __CategoryType;
    priority?: number;
};

export type __BrandType = {
    title: string;
    slug: string;
    description: string; // html
    phone?: string;
    mobile?: string;
    address?: string;
    website?: string;
    telegram?: string;
    instagram?: string;
    twitter?: string;
    gallery?: __AttachmentType[];
    average_stars?: number;
    total_orders?: number;
    main_image?: __AttachmentType;
    facebook?: string;
    discord?: string;
    tiktok?: string;
    youtube?: string;
    whatsapp?: string;
    reddit?: string;
    linkedin?: string;
    is_active?: boolean;
};

export type __ServiceType = {
    id: number;
    title: string;
    slug: string;
    description: string; // html
    use_terms: string; // html
    cash_back?: number;
    cash_back_type?: __CacheBackTypeEnum;
    extra_service?: string[];
    gallery?: __AttachmentType[];
    categories?: __CategoryType[];
    brand: __BrandType;
    avg_star?: number;
    total_orders?: number;
    created_at: string;
    lang?: string;
    is_ordered: number;
    cash_back_label?: string;
    locations?: __LocationType<'detail'>[];
    priority_number?: number;
    is_active?: boolean;
};

export enum __CacheBackTypeEnum {
    constant = 1,
    percent = 2,
}

export type __ProfileType = {
    first_name?: string;
    last_name?: string;
    email: string;
    phone?: string;
    profile?: __AttachmentType;
    is_service_provider?: boolean;
    brand?: __BrandType;
    total_orders?: number;
    total_save?: number;
    total_extra_services?: number;
    id: number;
    dating_profile?: __DatingProfileType;
    e_transfer_email?: string;
    tenant?: __TenantType[];
    houses_count?: number;
    avatar?: __AttachmentType;
};

export type __OrderType = {
    id: number;
    user: __ProfileType;
    service: __ServiceType;
    created_at: string; //ISOstring
    verification_status: __OrderStatusEnum;
    rate?: __RateEnum; // 1-5
    reviews?: __RateCommentEnum[];
    comment?: string;
    purchase_documents?: __AttachmentType[];
    upload_date?: string; //ISOstring
    confirm_date?: string; //ISOstring
    tacking_code?: string;
    rejected_date?: string; //ISOstring
    featured?: boolean;
    tracking_code?: number;
    user_profile: __AttachmentType;
    user_fullname: string;
    qr_code?: __AttachmentType;
};

export type __EventCategoryType = {
    title: string;
    slug: string;
    parent?: __EventCategoryType;
};

export type __EventType = {
    id: number;
    title: string;
    slug: string;
    category: __EventCategoryType;
    address?: string;
    place: string;
    event_date: string;
    capacity?: number;
    participant?: number;
    description?: string;
    conditions?: string;
    price: number;
    host?: __ProfileType;
    gallery?: __AttachmentType[];
    main_image: __AttachmentType;
    latitude: number;
    longitude: number;
};

export enum __MBTIEnum {
    INTJ,
    INTP,
    ENTJ,
    ENTP,
    INFJ,
    INFP,
    ENFJ,
    ENFP,
    ISTJ,
    ISFJ,
    ESTJ,
    ESFJ,
    ISTP,
    ISFP,
    ESTP,
    ESFP,
}

export type __HouseType = {
    id: number;
    location: __LocationType<'detail'>;
    building_type: __BuildingTypeEnum;
    price?: number;
    attachments?: __AttachmentType[];
    facilities?: __HouseFacilityEnum[];
    limitation?: __HouseLimitationEnum[];
    accommodation_type: __AccommodationTypeEnum;
    condition?: string;
    residential_duration: __ResidentialDurationEnum;
    latitude?: number;
    longitude?: number;
    owner?: __ProfileType;
    owner_birth_year?: number;
    owner_bio?: string;
    owner_gender?: __GenderEnum;
    desired_gender?: __GenderEnum;
    rent_type: __RentEnum;
    is_active?: boolean;
    owner_telegram_id: string;
};

export type __TenantType = {
    id: number;
    user?: __ProfileType;
    rent_type?: __RentEnum;
    is_active?: boolean;
    birth_year?: number;
    gender?: __GenderEnum;
    desired_gender?: __GenderEnum;
    bio?: string;
    budget?: number;
    condition?: string;
    location?: __LocationType;
    residential_duration?: __ResidentialDurationEnum;
    accommodation_type?: __AccommodationTypeEnum;
    building_type?: __BuildingTypeEnum;
    limitation?: __HouseLimitationEnum[];
    facilities?: __HouseFacilityEnum[];
    telegram_id?: string;
};

export type __DatingProfileType = {
    id: number;
    is_active: boolean;
    name: string;
    birth_year: string;
    gender: __GenderEnum;
    telegram_id: string;
    location?: __LocationType<'detail'>;
    chances_count?: number;
    free_chances_renewal_datetime?: string;
    gallery: __AttachmentType[];
    mbti?: __MBTIEnum;
    EI?: number;
    FT?: number;
    JP?: number;
    NS?: number;
    TA?: number;
} & __PersonalInfoType;

export type __PersonalInfoType = {
    [__PersonalInfoEnum.bodyShape]?: __BodyShapeEnum;
    [__PersonalInfoEnum.calmness]?: __CalmnessEnum;
    [__PersonalInfoEnum.cooking]?: __CookingEnum;
    [__PersonalInfoEnum.entertainment]?: __EntertainmentEnum;
    [__PersonalInfoEnum.height]?: __HeightEnum;
    [__PersonalInfoEnum.job]?: __JobEnum;
    [__PersonalInfoEnum.jobType]?: __JobTypeEnum;
    [__PersonalInfoEnum.sleep]?: __SleepEnum;
    [__PersonalInfoEnum.stay]?: __StayEnum;
    [__PersonalInfoEnum.travel]?: __TravelEnum;
};

export type __MBTIPersonalitiesDataType = {
    icon: IconType;
    lang: { index: string; desc: string };
    phrase: string;
    color: string;
    value: __MBTIEnum;
    matches: {
        perfect: [__MBTIEnum, __MBTIEnum, __MBTIEnum, __MBTIEnum];
        high: [__MBTIEnum, __MBTIEnum, __MBTIEnum, __MBTIEnum];
        moderate: [__MBTIEnum, __MBTIEnum, __MBTIEnum, __MBTIEnum];
        low: [__MBTIEnum, __MBTIEnum, __MBTIEnum, __MBTIEnum];
    };
};

export enum __HeightEnum {
    lessThan150,
    from150to160,
    from160to170,
    from170to180,
    from180to190,
    moreThan190,
}
export enum __SleepEnum {
    stayAwakeAtNight,
    earlyBird,
}
export enum __JobEnum {
    employer,
    entrepreneur,
    freelancer,
    housekeeper,
    consultant,
    teacher,
    doctor,
    personalBusiness,
}
export enum __CookingEnum {
    inHome,
    orderIn,
}
export enum __JobTypeEnum {
    attendanceWork,
    absenteeWork,
}
export enum __StayEnum {
    ecotourist,
    hotel,
}
export enum __CalmnessEnum {
    peaceAndQuiet,
    partyAndDance,
}
export enum __BodyShapeEnum {
    slenderBody,
    fit,
    muscular,
    aLittleOverweight,
    overweight,
}
export enum __TravelEnum {
    toUrban,
    toNature,
}
export enum __EntertainmentEnum {
    book,
    videoGame,
}

export enum __AccommodationTypeEnum {
    condo = 1,
    rental,
    townhouse,
    detached_house,
    basement,
}

export enum __BuildingTypeEnum {
    normal_room = 1,
    master_room,
    small_room,
    canape,
    independent_unit_common_entrance,
    independent_unit_independent_entrance,
}

export enum __RentEnum {
    Communal = 1,
    Individual,
}

export enum __HouseFacilityEnum {
    Parking = 1,
    Warehouse,
    Elevator,
}

export enum __HouseLimitationEnum {
    NoPets = 1,
    NoCigarettes,
    NoLoudSounds,
}

export enum __ResidentialDurationEnum {
    aYear = 1,
    sixMonths,
}

export enum __PersonalInfoEnum {
    height = 'height',
    sleep = 'sleep',
    job = 'job',
    cooking = 'cooking',
    jobType = 'job_type',
    stay = 'stay',
    calmness = 'calmness',
    bodyShape = 'body_shape',
    travel = 'travel',
    entertainment = 'entertainment',
}

export enum __GenderEnum {
    male = 1,
    female,
    noGender,
}

export enum __ParticipateStatusEnum {
    success = 1,
    rejected,
    pending,
}
export interface __SettingType {
    id: number;
    lang: string;
    key: string;
    value?: string;
    file?: __AttachmentType[];
}

export interface __LocationType<e = 'list' | 'detail'> {
    id: number;
    type: __LocationEnum;
    name: string;
    parent?: e extends 'detail' ? __LocationType<'list'> : number;
}

export enum __LocationEnum {
    region = 0,
    subRegion = 1,
    country = 2,
    state = 4,
    city = 5,
}

export enum __OrderStatusEnum {
    pending = 1,
    approved_by_brand,
    approved_by_admin,
    rejected_by_admin,
}

export enum __OrderShowStatusEnum {
    not_confirm,
    pending,
    success,
    success_without_review,
    fail,
}

export enum __RateCommentEnum {
    goodPrice = 1,
    goodSpace,
    goodResponse,
    goodSupport,
    goodQuality,
    goodInformation,
    badPrice,
    badSpace,
    badResponse,
    badSupport,
    badQuality,
    badInformation,
}

export enum __RateEnum {
    veryBad = 1,
    weak,
    medium,
    good,
    grate,
}

export type __ReviewType = Pick<
    __OrderType,
    | 'user'
    | 'service'
    | 'comment'
    | 'rate'
    | 'upload_date'
    | 'created_at'
    | 'reviews'
    | 'user_profile'
    | 'user_fullname'
>;
export interface __ParticipateType {
    id: number;
    user: __ProfileType;
    count: string;
    cerated_at: string;
    status: __ParticipateStatusEnum;
    description: string;
    event: __EventType;
}

export enum __ServiceTypeEnum {
    offer = 'offer',
    housing = 'housing',
    dating = 'dating',
    events = 'events',
    hiring = 'hiring',
}

export type __BlogPostType = {
    id: number;
    slug: string;
    title: string;
    created_at: string;
    image: __AttachmentType;
    categories?: __BlogCategoryType[];
    author?: __ProfileType;
    lang?: string;
    description?: string;
    content: string;
    tags?: string[];
};

export type __ExtraPageType = {
    id: number;
    slug: string;
    title?: string;
    created_at: string;
    image?: __AttachmentType;
    lang?: string;
    description?: string;
    content?: string;
    tags?: string[];
};

export type __BlogCategoryType = {
    id: number;
    slug: string;
    title: string;
    parent?: __BlogCategoryType;
    image?: __AttachmentType;
    lang: string;
};
