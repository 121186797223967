import { __HousesListLang } from '@/tehranto/housesList/housesList.lang';
import { LuHeartHandshake } from 'react-icons/lu';
import {
    TbBook,
    TbBrain,
    TbBulb,
    TbHeart,
    TbListSearch,
    TbMedal,
    TbMicrophone2,
    TbPalette,
    TbPuzzle,
    TbSettings,
    TbShieldStar,
    TbStar,
    TbSunHigh,
    TbTelescope,
    TbTool,
} from 'react-icons/tb';
import { __DatingLang } from 'tehranto/dating/dating.lang';
import {
    AccommodationTypeEnum,
    BodyShapeEnum,
    BuildingTypeEnum,
    CalmnessEnum,
    CookingEnum,
    EntertainmentEnum,
    GenderEnum,
    HeightEnum,
    HouseFacilityEnum,
    HouseLimitationEnum,
    HouseType,
    JobEnum,
    JobTypeEnum,
    MBTIEnum,
    MBTIPersonalitiesDataType,
    PersonalInfoEnum,
    RentEnum,
    ResidentialDurationEnum,
    SleepEnum,
    StayEnum,
    TravelEnum,
} from 'types';

const generateInfo = (
    e: object,
    k: PersonalInfoEnum,
    lang: typeof __DatingLang.moreInfo,
    indexLang: typeof __DatingLang.personalInfo,
) => {
    return (Object.values(e).filter((i) => typeof i === 'string') as Array<keyof typeof e>).map((key) => ({
        value: e[key],
        title: lang[k][e[key]],
        index: indexLang[k],
    }));
};

export const __PersonalInfoData = (
    lang: typeof __DatingLang.moreInfo,
    indexLang: typeof __DatingLang.personalInfo,
) => ({
    [PersonalInfoEnum.height]: generateInfo(HeightEnum, PersonalInfoEnum.height, lang, indexLang),
    [PersonalInfoEnum.sleep]: generateInfo(SleepEnum, PersonalInfoEnum.sleep, lang, indexLang),
    [PersonalInfoEnum.job]: generateInfo(JobEnum, PersonalInfoEnum.job, lang, indexLang),
    [PersonalInfoEnum.cooking]: generateInfo(CookingEnum, PersonalInfoEnum.cooking, lang, indexLang),
    [PersonalInfoEnum.jobType]: generateInfo(JobTypeEnum, PersonalInfoEnum.jobType, lang, indexLang),
    [PersonalInfoEnum.stay]: generateInfo(StayEnum, PersonalInfoEnum.stay, lang, indexLang),
    [PersonalInfoEnum.calmness]: generateInfo(CalmnessEnum, PersonalInfoEnum.calmness, lang, indexLang),
    [PersonalInfoEnum.bodyShape]: generateInfo(BodyShapeEnum, PersonalInfoEnum.bodyShape, lang, indexLang),
    [PersonalInfoEnum.travel]: generateInfo(TravelEnum, PersonalInfoEnum.travel, lang, indexLang),
    [PersonalInfoEnum.entertainment]: generateInfo(EntertainmentEnum, PersonalInfoEnum.entertainment, lang, indexLang),
});

export const __MBTIPersonalities = (lang: typeof __DatingLang.mbti): MBTIPersonalitiesDataType[] => [
    {
        icon: TbBrain,
        lang: lang[MBTIEnum.INTJ],
        phrase: 'INTJ',
        color: '#C2185B',
        value: MBTIEnum.INTJ,
        matches: {
            perfect: [MBTIEnum.ENTP, MBTIEnum.ENFP, MBTIEnum.INTJ, MBTIEnum.INFJ],
            high: [MBTIEnum.INTP, MBTIEnum.ENTJ, MBTIEnum.INFP, MBTIEnum.ENFJ],
            moderate: [MBTIEnum.ESTP, MBTIEnum.ESFP, MBTIEnum.ISTP, MBTIEnum.ISFP],
            low: [MBTIEnum.ESTJ, MBTIEnum.ISTJ, MBTIEnum.ISFJ, MBTIEnum.ESFJ],
        },
    },
    {
        icon: TbBulb,
        lang: lang[MBTIEnum.INTP],
        phrase: 'INTP',
        color: '#C2185B',
        value: MBTIEnum.INTP,
        matches: {
            perfect: [MBTIEnum.ENTJ, MBTIEnum.ESTJ, MBTIEnum.ENFJ, MBTIEnum.INTP],
            high: [MBTIEnum.INFP, MBTIEnum.INTJ, MBTIEnum.ENTP, MBTIEnum.INFJ],
            moderate: [MBTIEnum.ENFP, MBTIEnum.ESTP, MBTIEnum.ISTP, MBTIEnum.ISFP],
            low: [MBTIEnum.ESFP, MBTIEnum.ESFP, MBTIEnum.ISTJ, MBTIEnum.ISFJ],
        },
    },
    {
        icon: TbSunHigh,
        lang: lang[MBTIEnum.ENTJ],
        phrase: 'ENTJ',
        color: '#C2185B',
        value: MBTIEnum.ENTJ,
        matches: {
            perfect: [MBTIEnum.INTP, MBTIEnum.INFP, MBTIEnum.ENTJ, MBTIEnum.ENFJ],
            high: [MBTIEnum.ENTP, MBTIEnum.INTJ, MBTIEnum.ENFP, MBTIEnum.INFJ],
            moderate: [MBTIEnum.ISTP, MBTIEnum.ISFP, MBTIEnum.ISTJ, MBTIEnum.ESTJ],
            low: [MBTIEnum.ESFJ, MBTIEnum.ISFJ, MBTIEnum.ESTP, MBTIEnum.ESFP],
        },
    },
    {
        icon: TbTelescope,
        lang: lang[MBTIEnum.ENTP],
        phrase: 'ENTP',
        color: '#C2185B',
        value: MBTIEnum.ENTP,
        matches: {
            perfect: [MBTIEnum.INTJ, MBTIEnum.INFJ, MBTIEnum.ENTP, MBTIEnum.ENFP],
            high: [MBTIEnum.ENTJ, MBTIEnum.INTP, MBTIEnum.ENFJ, MBTIEnum.INFP],
            moderate: [MBTIEnum.ISTP, MBTIEnum.ESTP, MBTIEnum.ESFP, MBTIEnum.ISFP],
            low: [MBTIEnum.ISTJ, MBTIEnum.ISFJ, MBTIEnum.ESTJ, MBTIEnum.ESFJ],
        },
    },
    {
        icon: TbStar,
        lang: lang[MBTIEnum.INFJ],
        phrase: 'INFJ',
        color: '#05AD4B',
        value: MBTIEnum.INFJ,
        matches: {
            perfect: [MBTIEnum.ENFP, MBTIEnum.ENTP, MBTIEnum.INFJ, MBTIEnum.INTJ],
            high: [MBTIEnum.INFP, MBTIEnum.ENFJ, MBTIEnum.INTP, MBTIEnum.ENTJ],
            moderate: [MBTIEnum.ESFP, MBTIEnum.ESTP, MBTIEnum.ESFJ, MBTIEnum.ISFJ],
            low: [MBTIEnum.ISTJ, MBTIEnum.ESTJ, MBTIEnum.ISFP, MBTIEnum.ISTP],
        },
    },
    {
        icon: TbHeart,
        lang: lang[MBTIEnum.INFP],
        phrase: 'INFP',
        color: '#05AD4B',
        value: MBTIEnum.INFP,
        matches: {
            perfect: [MBTIEnum.ENFJ, MBTIEnum.ENTJ, MBTIEnum.INFP, MBTIEnum.INTP],
            high: [MBTIEnum.INFJ, MBTIEnum.ENFP, MBTIEnum.INTJ, MBTIEnum.ENTP],
            moderate: [MBTIEnum.ESFJ, MBTIEnum.ESTJ, MBTIEnum.ESFP, MBTIEnum.ISFP],
            low: [MBTIEnum.ISTP, MBTIEnum.ESTP, MBTIEnum.ISFJ, MBTIEnum.ISTJ],
        },
    },
    {
        icon: TbBook,
        lang: lang[MBTIEnum.ENFJ],
        phrase: 'ENFJ',
        color: '#05AD4B',
        value: MBTIEnum.ENFJ,
        matches: {
            perfect: [MBTIEnum.INFP, MBTIEnum.ISFP, MBTIEnum.INTP, MBTIEnum.ENFJ],
            high: [MBTIEnum.ENTJ, MBTIEnum.ENFP, MBTIEnum.ENTP, MBTIEnum.INFJ],
            moderate: [MBTIEnum.INTJ, MBTIEnum.ISTP, MBTIEnum.ISFJ, MBTIEnum.ESFJ],
            low: [MBTIEnum.ESTJ, MBTIEnum.ISTJ, MBTIEnum.ESFP, MBTIEnum.ESTP],
        },
    },
    {
        icon: TbMedal,
        lang: lang[MBTIEnum.ENFP],
        phrase: 'ENFP',
        color: '#05AD4B',
        value: MBTIEnum.ENFP,
        matches: {
            perfect: [MBTIEnum.INFJ, MBTIEnum.INTJ, MBTIEnum.ENFP, MBTIEnum.ENTP],
            high: [MBTIEnum.ENFJ, MBTIEnum.INFP, MBTIEnum.ENTJ, MBTIEnum.INTP],
            moderate: [MBTIEnum.ISFJ, MBTIEnum.ISTJ, MBTIEnum.ISFP, MBTIEnum.ESFP],
            low: [MBTIEnum.ESTP, MBTIEnum.ISTP, MBTIEnum.ESFJ, MBTIEnum.ESTJ],
        },
    },
    {
        icon: TbListSearch,
        lang: lang[MBTIEnum.ISTJ],
        phrase: 'ISTJ',
        color: '#1A237E',
        value: MBTIEnum.ISTJ,
        matches: {
            perfect: [MBTIEnum.ESTP, MBTIEnum.ESFP, MBTIEnum.ISTJ, MBTIEnum.ISFJ],
            high: [MBTIEnum.ESTJ, MBTIEnum.ESFJ, MBTIEnum.ISTP, MBTIEnum.ISFP],
            moderate: [MBTIEnum.ENTJ, MBTIEnum.ENTP, MBTIEnum.INTJ, MBTIEnum.INTP],
            low: [MBTIEnum.ENFP, MBTIEnum.INFJ, MBTIEnum.ENFJ, MBTIEnum.INFP],
        },
    },
    {
        icon: TbShieldStar,
        lang: lang[MBTIEnum.ISFJ],
        phrase: 'ISFJ',
        color: '#1A237E',
        value: MBTIEnum.ISFJ,
        matches: {
            perfect: [MBTIEnum.ESFP, MBTIEnum.ESTP, MBTIEnum.ISFJ, MBTIEnum.ISTJ],
            high: [MBTIEnum.ESFJ, MBTIEnum.ESTJ, MBTIEnum.ISFP, MBTIEnum.ISTP],
            moderate: [MBTIEnum.ENTJ, MBTIEnum.ENTP, MBTIEnum.INTJ, MBTIEnum.INTP],
            low: [MBTIEnum.ENFP, MBTIEnum.ENFJ, MBTIEnum.INFJ, MBTIEnum.INFP],
        },
    },
    {
        icon: TbPuzzle,
        lang: lang[MBTIEnum.ESTJ],
        phrase: 'ESTJ',
        color: '#1A237E',
        value: MBTIEnum.ESTJ,
        matches: {
            perfect: [MBTIEnum.ISTP, MBTIEnum.ISFP, MBTIEnum.INTP, MBTIEnum.ESTJ],
            high: [MBTIEnum.ESFJ, MBTIEnum.ISTJ, MBTIEnum.ISFJ, MBTIEnum.ESTP],
            moderate: [MBTIEnum.ESFP, MBTIEnum.ENTJ, MBTIEnum.INTJ, MBTIEnum.ENTP],
            low: [MBTIEnum.INFP, MBTIEnum.ENFJ, MBTIEnum.INFJ, MBTIEnum.ENFP],
        },
    },
    {
        icon: LuHeartHandshake,
        lang: lang[MBTIEnum.ESFJ],
        phrase: 'ESFJ',
        color: '#1A237E',
        value: MBTIEnum.ESFJ,
        matches: {
            perfect: [MBTIEnum.ISFP, MBTIEnum.ISTP, MBTIEnum.ESFJ, MBTIEnum.ESTJ],
            high: [MBTIEnum.ISFJ, MBTIEnum.ISTJ, MBTIEnum.ESFP, MBTIEnum.ESTP],
            moderate: [MBTIEnum.ENTJ, MBTIEnum.INTP, MBTIEnum.INTJ, MBTIEnum.ENTP],
            low: [MBTIEnum.INFP, MBTIEnum.INFJ, MBTIEnum.ENFJ, MBTIEnum.ENFP],
        },
    },
    {
        icon: TbTool,
        lang: lang[MBTIEnum.ISTP],
        phrase: 'ISTP',
        color: '#FF6F00',
        value: MBTIEnum.ISTP,
        matches: {
            perfect: [MBTIEnum.ESTJ, MBTIEnum.ESFJ, MBTIEnum.ENTJ, MBTIEnum.ISTJ],
            high: [MBTIEnum.ENTP, MBTIEnum.ISFJ, MBTIEnum.INTP, MBTIEnum.INTJ],
            moderate: [MBTIEnum.ISTP, MBTIEnum.ISFP, MBTIEnum.ESTP, MBTIEnum.ESFP],
            low: [MBTIEnum.ENFJ, MBTIEnum.INFP, MBTIEnum.ENFP, MBTIEnum.INFJ],
        },
    },
    {
        icon: TbPalette,
        lang: lang[MBTIEnum.ISFP],
        phrase: 'ISFP',
        color: '#FF6F00',
        value: MBTIEnum.ISFP,
        matches: {
            perfect: [MBTIEnum.ESFJ, MBTIEnum.ESTJ, MBTIEnum.ENFJ, MBTIEnum.ENTJ],
            high: [MBTIEnum.ISFJ, MBTIEnum.ISTJ, MBTIEnum.INTP, MBTIEnum.ENTP],
            moderate: [MBTIEnum.INTJ, MBTIEnum.ISFP, MBTIEnum.ISTP, MBTIEnum.ESFP],
            low: [MBTIEnum.ESTP, MBTIEnum.ENFP, MBTIEnum.INFP, MBTIEnum.INFJ],
        },
    },
    {
        icon: TbSettings,
        lang: lang[MBTIEnum.ESTP],
        phrase: 'ESTP',
        color: '#FF6F00',
        value: MBTIEnum.ESTP,
        matches: {
            perfect: [MBTIEnum.ISTJ, MBTIEnum.ISFJ, MBTIEnum.INTJ, MBTIEnum.ESTJ],
            high: [MBTIEnum.INTP, MBTIEnum.ENTP, MBTIEnum.ESFJ, MBTIEnum.ENTJ],
            moderate: [MBTIEnum.ESTP, MBTIEnum.ESFP, MBTIEnum.ISTP, MBTIEnum.ISFP],
            low: [MBTIEnum.INFJ, MBTIEnum.ENFP, MBTIEnum.INFP, MBTIEnum.ENFJ],
        },
    },
    {
        icon: TbMicrophone2,
        lang: lang[MBTIEnum.ESFP],
        phrase: 'ESFP',
        color: '#FF6F00',
        value: MBTIEnum.ESFP,
        matches: {
            perfect: [MBTIEnum.ISFJ, MBTIEnum.ISTJ, MBTIEnum.INTJ, MBTIEnum.ESFJ],
            high: [MBTIEnum.ESTJ, MBTIEnum.ENTP, MBTIEnum.INTP, MBTIEnum.ENTJ],
            moderate: [MBTIEnum.ESFP, MBTIEnum.ESTP, MBTIEnum.ISFP, MBTIEnum.ISTP],
            low: [MBTIEnum.INFJ, MBTIEnum.INFP, MBTIEnum.ENFP, MBTIEnum.ENFJ],
        },
    },
];

export const __AccommodationTypeData = [
    { label: 'Condo', value: AccommodationTypeEnum.condo },
    { label: 'Rental', value: AccommodationTypeEnum.rental },
    { label: 'Townhouse', value: AccommodationTypeEnum.townhouse },
    { label: 'Detached House', value: AccommodationTypeEnum.detached_house },
    { label: 'Basement', value: AccommodationTypeEnum.basement },
];

export const __BuildingTypeData = (lang: typeof __HousesListLang) => [
    { label: lang.normal_room, value: BuildingTypeEnum.normal_room },
    { label: lang.master_room, value: BuildingTypeEnum.master_room },
    { label: lang.small_room, value: BuildingTypeEnum.small_room },
    { label: lang.canape, value: BuildingTypeEnum.canape },
    { label: lang.independent_common, value: BuildingTypeEnum.independent_unit_common_entrance },
    { label: lang.independent_independent, value: BuildingTypeEnum.independent_unit_independent_entrance },
];

export const __RentTypeData = (lang: typeof __HousesListLang) => [
    { label: lang.independent, value: RentEnum.Individual },
    { label: lang.shared, value: RentEnum.Communal },
];

export const __GenderData = (lang: typeof __HousesListLang) => [
    { label: lang.male, value: GenderEnum.male },
    { label: lang.female, value: GenderEnum.female },
];

export const __HouseLimitationData = (lang: typeof __HousesListLang) => [
    { label: lang.NoPets, value: HouseLimitationEnum.NoPets },
    { label: lang.NoCigarettes, value: HouseLimitationEnum.NoCigarettes },
    { label: lang.NoLoudSounds, value: HouseLimitationEnum.NoLoudSounds },
];

export const __HouseFacilityData = (lang: typeof __HousesListLang) => [
    { label: lang.parking, value: HouseFacilityEnum.Parking },
    { label: lang.warehouse, value: HouseFacilityEnum.Warehouse },
    { label: lang.elevator, value: HouseFacilityEnum.Elevator },
];

export const __ResidentialDurationData = (lang: typeof __HousesListLang) => [
    { label: lang.aYear, value: ResidentialDurationEnum.aYear },
    { label: lang.sixMonth, value: ResidentialDurationEnum.sixMonths },
];

export const __HouseTitle = (house: HouseType, lang: typeof __HousesListLang) =>
    __BuildingTypeData(lang).find((i) => i.value === house.building_type)?.label;
